<ng-container *ngIf="fetching">
    <mat-spinner mode="indeterminate" aria-hidden="true"></mat-spinner>
    <p>Logging you in</p>
</ng-container>
<ng-container *ngIf="success">
    <p>
        You're successfully logged in. Close this tab and go back to where you were logging in.
    </p>
</ng-container>
<ng-container *ngIf="error">
    <p class="text-error">{{error}}</p>
</ng-container>
