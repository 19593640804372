import {Injectable} from '@angular/core';
import {ApolloCache} from '@apollo/client/cache/core/cache';
import {PossibleTypesMap} from '@apollo/client/cache/inmemory/policies';
import {NormalizedCacheObject} from '@apollo/client/cache/inmemory/types';
import {InMemoryCache} from '@apollo/client/core';
import {CachePersistor} from 'apollo3-cache-persist';
import {
    buildClientSchema,
    getIntrospectionQuery,
    GraphQLSchema,
    IntrospectionQuery,
} from 'graphql';
import localForage from 'localforage';

import {environment} from '../../environments/environment';
import {ExtendedError} from '../error/extended.error';
import {murmurhash3} from '../shared/utils/murmur-hash';

@Injectable({
    providedIn: 'root',
})
export class GraphqlService {
    private static FingerPrintToken = 'schemaFingerprint';
    private static IntrospectionToken = 'introspection';

    readonly endpoint = `${environment.api}/graphql`;

    private _cache: ApolloCache<NormalizedCacheObject>;
    get cache(): ApolloCache<NormalizedCacheObject> {
        return this._cache;
    }

    private _hasSchemaChanged: boolean;

    private _schema: GraphQLSchema;
    get schema(): GraphQLSchema {
        return this._schema;
    }

    async fetchSchema() {
        let introspection: IntrospectionQuery;
        let introspectionString: string;

        try {
            const body = JSON.stringify({
                query: getIntrospectionQuery({
                    descriptions: false,
                }),
            });

            const response = await fetch(this.endpoint, {
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
            introspection = (await response.json() as {data: IntrospectionQuery}).data;
            introspectionString = JSON.stringify(introspection);
            localStorage.setItem(GraphqlService.IntrospectionToken, introspectionString);
        } catch (err) {
            const oldIntrospection = localStorage.getItem(GraphqlService.IntrospectionToken);

            if (oldIntrospection === null) {
                throw new ExtendedError('Could not fetch GraphQL Schema', {
                    endpoint: this.endpoint,
                    originalError: err,
                });
            } else {
                introspection = JSON.parse(oldIntrospection);
                introspectionString = oldIntrospection;
            }
        }

        this._schema = buildClientSchema(introspection);
        this.fingerprint(introspectionString);
        await this.setupCache(introspection);
    }

    private fingerprint(schema: string): void {
        const fingerprint = murmurhash3(schema).toString();
        const oldFingerprint = localStorage.getItem(GraphqlService.FingerPrintToken);
        this._hasSchemaChanged = fingerprint !== oldFingerprint;
        localStorage.setItem(GraphqlService.FingerPrintToken, fingerprint);
    }

    private async setupCache(introspection: IntrospectionQuery): Promise<void> {
        const possibleTypes = introspection.__schema.types.reduce<PossibleTypesMap>(
            (acc, supertype) => {
                if (supertype.kind === 'INTERFACE') {
                    acc[supertype.name] = supertype.possibleTypes.map(subtype => {
                        return subtype.name;
                    });
                }

                return acc;
            },
            {},
        );

        this._cache = new InMemoryCache({
            possibleTypes: possibleTypes,
        });

        const persistor = new CachePersistor({
            cache: this._cache,
            storage: localForage,
        });

        if (this._hasSchemaChanged) {
            await persistor.purge();
        } else {
            await persistor.restore();
        }
    }

}
