import {ParsingFunctionsObject} from 'apollo-link-scalars';
import {format, parseISO, startOfDay} from 'date-fns';

function parseDate(value: string): Date {
    const date = parseISO(value);

    if (isNaN(date.getTime())) {
        throw new Error('Value cannot be parsed as date. Use the yyyy-mm-dd format.');
    }

    return date;
}

export const dateType: ParsingFunctionsObject = {
    parseValue(value?: string | null): Date | null {
        if (value == null) {
            return null;
        }

        return startOfDay(parseDate(value));
    },
    serialize(value?: Date | string | null): string | null | undefined {
        if (value == null) {
            return value;
        }

        if (typeof value === 'string') {
            return value;
        }

        return format(value, 'yyyy-MM-dd');
    },
};

export const dateInSecondsSerializer = {
    toInstance: (value: Date | number | null | undefined) => {
        if (value == null || value instanceof Date) {
            return value;
        }

        return new Date(value * 1000);
    },
    toPlain: (value: Date | null | undefined) => {
        return value == null ? value : Math.trunc(value.getTime() / 1000);
    },
};
