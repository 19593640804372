<h1>Log in</h1>
<form (ngSubmit)="submit()" [formGroup]="form" *ngIf="!polling">
    <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput
               formControlName="email"
               type="email"
               placeholder="user@example.com"
               autocomplete="username"
               autofocus
               required>
        <mat-error *ngFor="let e of errRepo.getErrors('email')">
            {{e.message}}
        </mat-error>
    </mat-form-field>
    <p *ngIf="error" class="text-error">{{error}}</p>
    <button type="submit" mat-flat-button color="primary">
        <span *ngIf="!submitting">Log in</span>
        <mat-spinner *ngIf="submitting"
                     class="inline spinner-on-primary"
                     [diameter]="17"
                     color="accent"
        ></mat-spinner>
    </button>
</form>
<p *ngIf="polling">
    We've sent you an email, please click the link inside.
</p>
