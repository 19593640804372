import {Environment} from './environment.interface';
import {getWindow} from './environment.util';

export const environment: Environment = {
    api: 'https://api.home.sd4u.be',
    environment: 'production',
    persistCache: true,
    production: true,
    release: getWindow()?.env?.release,
    sentry: {
        dsn: 'https://daeb5e2c3cc64b2a8ed7dc714880ce18@o446800.ingest.sentry.io/5425863',
        enabled: true,
    },
    serviceWorkerEnabled: true,
};
