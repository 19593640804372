import {model, property} from 'decoverto';

@model()
export class User {

    @property()
    email: string;

    @property()
    id: string;

    @property()
    name: string;

    get initials(): string {
        return this.name.split(' ').map(word => word.substring(0, 1)).join('').substring(0, 3);
    }
}
