import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {finalize, take} from 'rxjs/operators';

import {Logger} from '../../shared/utils/logger.util';
import {AuthService} from '../auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

    error: string | null = null;
    loggingIn = false;

    constructor(
        private readonly auth: AuthService,
        private readonly router: Router,
    ) {
    }

    login(token: string): void {
        this.loggingIn = true;
        this.auth.logIn(token).pipe(
            take(1),
            finalize(() => {
                this.loggingIn = false;
            }),
        ).subscribe(user => {
            this.router.navigate(['']).catch(Logger.errorWrap);
        }, error => {
            this.error = Logger.handleRequestError({
                error,
                message: 'Login error',
            });
        });
    }
}
