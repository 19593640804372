import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AnonymousGuard} from './auth/guards/anonymous.guard';
import {LoginComponent} from './auth/login/login.component';
import {MagicLinkSecretComponent} from './auth/magic-link/secret/magic-link-secret.component';
import {NotFoundComponent} from './error/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'dashboard',
        loadChildren: async () => import('./dashboard/dashboard.module')
            .then(m => m.DashboardModule),
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AnonymousGuard],
    },
    {
        path: 'magic-link/:secret',
        component: MagicLinkSecretComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
