import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import locale from '@angular/common/locales/en-BE';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import {Decoverto} from 'decoverto';
import {ToastrModule} from 'ngx-toastr';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './auth/auth.service';
import {LoginComponent} from './auth/login/login.component';
import {MagicLinkComponent} from './auth/magic-link/magic-link.component';
import {MagicLinkSecretComponent} from './auth/magic-link/secret/magic-link-secret.component';
import {TokenInterceptorService} from './auth/token-interceptor.service';
import {NotFoundComponent} from './error/not-found/not-found.component';
import {CustomErrorHandler} from './error/sentry/custom-error.handler';
import {GraphQLModule} from './grapqhl/graphql.module';
import {DateFnsDateAdapter} from './shared/utils/date-fns-date-adapter';
import {decoverto} from './shared/utils/decoverto.util';
import {UpdateService} from './update/update.service';

registerLocaleData(locale);

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
    appearance: 'outline',
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MagicLinkComponent,
        MagicLinkSecretComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        GraphQLModule,
        HttpClientModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.serviceWorkerEnabled}),
        ToastrModule.forRoot(),
        AppRoutingModule,
    ],
    providers: [
        {provide: ErrorHandler, useClass: CustomErrorHandler},
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {provide: LOCALE_ID, useValue: 'en-BE'},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions},
        {
            provide: DateAdapter,
            useClass: DateFnsDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['dd/MM/yyyy', 'dd-MM-yyyy', 'yyyy-MM-dd'],
                },
                display: {
                    dateInput: 'dd/MM/yyyy',
                    monthYearLabel: 'LLL y',
                    dateA11yLabel: 'MMMM d, y',
                    monthYearA11yLabel: 'MMMM y',
                },
            },
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        UpdateService,
        {provide: Decoverto, useValue: decoverto},
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => undefined,
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
