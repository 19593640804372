import {ErrorRepository} from 'angular-error-repository';

import {emailError} from './email.error-message';
import {maxError} from './max.error-message';
import {minLengthError} from './min-length.error-message';
import {minError} from './min.error-message';
import {requiredError} from './required.error-message';
import {selectItemError} from './select-item.error-message';

let initialized = false;

export function initErrorMessages() {
    if (initialized) {
        return;
    }

    initialized = true;

    ErrorRepository.errorMessages['email'] = emailError;
    ErrorRepository.errorMessages['max'] = maxError;
    ErrorRepository.errorMessages['min'] = minError;
    ErrorRepository.errorMessages['minlength'] = minLengthError;
    ErrorRepository.errorMessages['required'] = requiredError;
    ErrorRepository.errorMessages['selectItem'] = selectItemError;
}
