import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {
    map,
    take,
} from 'rxjs/operators';

import {AuthService} from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {

    constructor(
        private readonly auth: AuthService,
        private readonly router: Router,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.auth.getUser().pipe(
            take(1),
            map(user => user !== null),
            map(authenticated => authenticated ? this.router.parseUrl('/dashboard') : true),
        );
    }
}
