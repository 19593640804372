import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {configureScope} from '@sentry/browser';
import {Decoverto} from 'decoverto';
import {BehaviorSubject, concat, interval, Observable, of, throwError} from 'rxjs';
import {catchError, filter, finalize, map, share, takeWhile} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {apolloGraphqlErrorHandlerSingle} from '../grapqhl/graphql-error-handler';
import {GraphqlFetchResult} from '../grapqhl/graphql.interface';
import {Logger} from '../shared/utils/logger.util';
import {Jwt} from './jwt.model';
import {User} from './user.model';

type TokenRenewal = 'renewing' | 'none' | 'cancelled';

@Injectable()
export class AuthService {
    private static readonly CACHED_USER = 'cachedUser';
    private static readonly TOKEN = 'token';
    private static readonly TOKEN_REFRESHING = 'tokenRefreshing';

    private tokenRefresher: Observable<Jwt> | null = null;
    /**
     * A token can be refreshing in this tab or another one.
     */
    private tokenRefreshingHere = false;
    private readonly user: BehaviorSubject<User | null>;
    private userRefresh: Observable<User | null> | null = null;
    private userIsInitialized = false;

    constructor(
        private readonly decoverto: Decoverto,
        private readonly http: HttpClient,
        private readonly router: Router,
    ) {
        this.user = new BehaviorSubject<User | null>(null);
        window.addEventListener('unload', () => {
            if (this.tokenRefreshingHere) {
                this.setTokenRefreshingHere('cancelled');
            }
        });
    }

    /**
     * Get the current token used for auth.
     */
    getToken(): Jwt | null {
        const encodedToken = localStorage.getItem(AuthService.TOKEN);

        if (encodedToken === null) {
            return null;
        }

        return Jwt.fromEncoded(encodedToken);
    }

    /**
     * It is possible that concurrent requests in multiple tabs each try to
     * renew the access token. In order to avoid lockout due to reusage of a
     * single use refresh token, the other tabs need to be made aware of an
     * ongoing token refresh.
     *
     * A flag ('renewing') is set in LocalStorage signaling a token renewal.
     * When the token finishes renewal, the flag is removed and this method will
     * return 'none'. If the tab is closed before finishing the request, the
     * token renewal is marked as cancelled and the user is signed out since
     * the API could already have spend the token.
     * @returns {TokenRenewal} 'renewing' | 'none' | 'cancelled'
     */
    getTokenRefreshingInOtherTab(): TokenRenewal {
        const tokenRefreshing = localStorage.getItem(AuthService.TOKEN_REFRESHING) as any;
        return tokenRefreshing ?? 'none';
    }

    /**
     * Returns an observable which emits the authenticated user. If no user is
     * authenticated, null is returned. When a property of the authenticated is
     * updated, a new user object is emitted.
     */
    getUser(): Observable<User | null> {
        if (!this.userIsInitialized) {
            return this.refreshUser();
        }

        return this.user;
    }

    /**
     * Get a snapshot of the current user.
     */
    getUserSnapshot(): User | null {
        return this.user.getValue();
    }

    /**
     * Checks whether an OAuth token is present.
     */
    hasToken(): boolean {
        return this.getToken() !== null;
    }

    logIn(token: string): Observable<User | null> {
        this.setToken(token);
        return this.refreshUser();
    }

    /**
     * Sign out, update the currently authenticated user, and redirect to login.
     */
    logOut(): void {
        this.clearToken();
        // @todo logout API call
        this.nextUser(null);
        this.router.navigate(['/login']).catch(Logger.errorWrap);
    }

    /**
     * Emit a new user object which will replace the currently authenticated
     * user.
     */
    nextUser(user: User | null): void {
        configureScope((scope) => {
            scope.setUser(user === null
                ? null
                : {
                    email: user.email,
                    id: user.id,
                    username: user.name,
                });
        });
        this.userIsInitialized = true;
        localStorage.setItem(AuthService.CACHED_USER, JSON.stringify(user));
        this.user.next(user);
    }

    /**
     * Fetch a new access token using the refresh token. To be called when the
     * access token has expired. Returns the new token.
     */
    refreshToken(): Observable<Jwt> {
        // If a token refresh is already in progress, return it. This prevents
        // multiple concurrent token refreshes
        if (this.tokenRefresher !== null) {
            return this.tokenRefresher;
        }

        const token = this.getToken();
        if (token === null) {
            return throwError('No token to refresh.');
        }

        if (this.getTokenRefreshingInOtherTab() !== 'none') {
            this.tokenRefresher = interval(50).pipe(
                finalize(() => {
                    this.tokenRefresher = null;
                }),
                map(() => this.getTokenRefreshingInOtherTab()),
                takeWhile(r => r === 'renewing', true),
                filter(r => r !== 'renewing'),
                map(r => {
                    const t = this.getToken();
                    if (t === null || r === 'cancelled') {
                        this.logOut();
                        throw new Error('Token was not renewed in other tab.');
                    }

                    return t;
                }),
                share(),
            );

            return this.tokenRefresher;
        }

        this.setTokenRefreshingHere('renewing');

        this.tokenRefresher = this.http.post(
            `${environment.api}/auth/refresh-jwt`,
            null,
            {
                responseType: 'text',
            },
        ).pipe(
            finalize(() => {
                this.tokenRefresher = null;
                this.setTokenRefreshingHere('none');
            }),
            map(data => {
                this.setToken(data);
                return Jwt.fromEncoded(data);
            }),
            share(), // Prevent multiple subscription from causing multiple requests
        );

        return this.tokenRefresher;
    }

    /**
     * Refreshes the current user. This updates the AuthService's user
     * observable.
     * The returned observable keeps emitting the currently authenticated user.
     */
    refreshUser(): Observable<User | null> {
        // If a user refresh is already in progress, return it. This prevents
        // multiple concurrent refreshes
        if (this.userRefresh !== null) {
            return this.userRefresh;
        }

        if (!this.hasToken()) {
            this.nextUser(null);
            return this.user;
        }

        this.userRefresh = concat(
            this.http.post<GraphqlFetchResult>(`${environment.api}/graphql`, {
                    query: `{
                        me {
                            email
                            id
                            name
                        }
                    }`,
            }).pipe(
                finalize(() => {
                    this.userRefresh = null;
                }),
                map(apolloGraphqlErrorHandlerSingle),
                // If any error occurs when the user is being fetched, the user is not authenticated
                // and null is emitted.
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        if (Logger.isStatusOffline(err.status)) {
                            const cachedUser = localStorage.getItem(AuthService.CACHED_USER);

                            if (cachedUser === null) {
                                return of(null);
                            }

                            return of(this.decoverto.type(User).rawToInstance(cachedUser));
                        } else {
                            Logger.error({
                                error: err,
                                message: `Error refreshing user`,
                            });
                        }
                    }

                    return of(null);
                }),
                map(data => {
                    const user = data == null
                        ? null
                        : this.decoverto.type(User).plainToInstance(data);

                    this.nextUser(user);
                    return user;
                }),
                share(), // Prevent multiple subscription from causing multiple requests
            ),
            this.user, // First emit the refreshed user then continue emitting the user
        );

        return this.userRefresh;
    }

    /**
     * Store a refreshing flag to signal other tabs to wait for token renewal
     * and not try to renew the token themselves.
     */
    setTokenRefreshingHere(state: TokenRenewal): void {
        this.tokenRefreshingHere = state === 'renewing';
        if (state === 'none') {
            localStorage.removeItem(AuthService.TOKEN_REFRESHING);
        } else {
            localStorage.setItem(AuthService.TOKEN_REFRESHING, state);
        }
    }

    private clearToken(): void {
        localStorage.removeItem(AuthService.TOKEN);
    }

    /**
     * Persists the token.
     */
    private setToken(token: string | null): void {
        if (token === null) {
            this.clearToken();
            return;
        }

        this.setTokenRefreshingHere('none');
        localStorage.setItem(AuthService.TOKEN, token);
    }
}
