import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ApolloClientOptions, from} from '@apollo/client/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {withScalars} from 'apollo-link-scalars';

import {GraphqlService} from './graphql.service';
import {dateType} from './types/date.type';

const typesMap = {
    Date: dateType,
};

export function createApollo(
    httpLink: HttpLink,
    graphqlService: GraphqlService,
): ApolloClientOptions<any> {
    const link = from([
        withScalars({
            schema: graphqlService.schema,
            typesMap,
        }),
        httpLink.create({uri: graphqlService.endpoint}),
    ]);

    return {
        link: link,
        cache: graphqlService.cache,
        defaultOptions: {
            mutate: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
            query: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                errorPolicy: 'all',
                fetchPolicy: 'cache-and-network',
            },
        },
    };
}

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (graphqlService: GraphqlService) => {
                return async () => graphqlService.fetchSchema();
            },
            deps: [GraphqlService],
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                HttpLink,
                GraphqlService,
            ],
        },
    ],
})
export class GraphQLModule {
}
