import {isPast} from 'date-fns';
import {model, property} from 'decoverto';
import * as jwtDecode from 'jwt-decode';

import {ExtendedError} from '../error/extended.error';
import {dateInSecondsSerializer} from '../grapqhl/types/date.type';
import {decoverto} from '../shared/utils/decoverto.util';

@model()
export class UserJwt {

    @property()
    email: string;

    @property()
    id: string;

    @property()
    name: string;
}

@model()
export class Jwt {

    /**
     * The client identifier of the OAuth 2.0 [RFC6749] client that requested the token.
     */
    @property()
    client_id: string;

    /**
     * Encoded form of this JWT.
     */
    @property()
    encodedForm: string;

    /**
     * Date at which the JWT will become invalid.
     */
    @property(dateInSecondsSerializer)
    exp: Date;

    /**
     * Issued at.
     */
    @property(dateInSecondsSerializer)
    iat: Date;

    /**
     * Token to refresh the JWT when it expires.
     */
    @property()
    refreshToken: string;

    /**
     * Space separated list of claims.
     * e.g. files:read files:write user.profile.basic:read
     */
    @property()
    scope: string;

    /**
     * Last time this JWT was updated.
     */
    @property(dateInSecondsSerializer)
    updated_at: Date;

    @property()
    user: UserJwt;

    static fromEncoded(encoded: string): Jwt {
        try {
            const payload = jwtDecode<any>(encoded);
            payload.encodedForm = encoded;
            return decoverto.type(Jwt).plainToInstance(payload);
        } catch (e) {
            throw new ExtendedError('JWT decoding error', {
                encoded: encoded,
                originalError: e,
            });
        }
    }

    isExpired(): boolean {
        return isPast(this.exp);
    }
}
